<template>
  <div class="loadingBox">
    <!-- 加载 -->
    <div class="loading" v-if="loading">
      <i class="el-icon-loading"></i>
      {{ txt }}
    </div>
    <!-- 其他 -->
    <div class="emptybox" v-if="!loading">
      <!-- 数据为空 -->
      <div class="nodata" v-if="list.length == 0">
        <img :src="imgUrl" alt="">
        <p class="txt">暂时没有数据哦！</p>
      </div>
      <!-- 暂时没有数据 -->
      <div class="nomore" v-if="page == totalPage && page != 1">
        <p class="txt">～没有更多了～</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    // 加载状态
    loading: {
      type: Boolean,
      default: false
    },
    //  数组内容，
    list: {
      type: Array,
      default: () => []
    },
    txt: {
      type: String,
      default: '正在拼命加载中...'
    },
    // 当前页数
    page: {
      type: Number,
      default: 1
    },
    // 总的页数
    totalPage: {
      type: Number,
      default: 1
    },
    img_url: {
      type: String,
      default: 'no_img_c.png'
    }
  },
  data() {
    return {}
  },
  watch: {},
  computed: {
    imgUrl() {
      if (this.img_url) {
        return require('@/assets/images/no_html/' + this.img_url)
      }
    },
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
.loadingBox {
  padding: 10px;

  .loading {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #C1C1C1;
  }

  .emptybox {
    .txt {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #C1C1C1;
      text-align: center;
    }

    .nodata {
      padding: 0 50px;
    }

    .nomore {
      padding: 0 50px;
    }
  }
}</style>
